import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHero  from "../components/template-partials/page-hero";
import ReferenceBlock from "../components/template-partials/reference-block";
import FooterCrown from "../components/template-partials/footer-crown";
import FlipCard from "../components/template-partials/flip-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { AnchorLink } from "gatsby-plugin-anchor-links";

//images
import germBg from "../images/germ-bg.png";
import bgsun from "../images/bgsun.png";
import lesionsBg from "../images/lesionsBg.jpg";
import lesionsBgMobile from "../images/lesionsbgMobile.png";

import aboutSmDykBg from "../images/about-sm-dyk-bg.png";
import aboutSmProgrsssBg from "../images/about-sm-progress-bg.png";

import aboutSmProgress from "../images/about-sm-progress-desktop.png";
import aboutSmDykLesions from "../images/about-sm-dyk-desktop.png";

import aboutDesktop from "../images/about-sm-chart-desktop.svg";
import aboutMobile from "../images/about-sm-chart-mobile.svg";
import responsivepc from "../images/resp-video-pc.png";

import anaphylaxisCard from "../images/anaphylaxis-card.png";
import skinLesionsCard from "../images/skin-lesions-card.png";
import diarrheaCard from "../images/diarrhea-card.png";

import gastroCard from "../images/gastro-card.png"
import neuroCard from "../images/neuro-card.png"
import cardioCard from "../images/cardio-card.png"
import skinCard from "../images/skin-card.png"
import musculoCard from "../images/musculoskeletal-card.png"
import respiratoryCard from "../images/respiratory-card.png"
import systemicCard from "../images/systemic-card.png"

import systemic from "../images/card-systemic.png"
import cardiovascular from "../images/card-cardio.png"
import musculoskeletal from "../images/card-musculo.png"
import respiratory from "../images/card-respiratory.png"
import neuropsychiatric from "../images/card-neuro.png"

import gi from "../images/card-gastro.png";
import agChart from "../images/card-artery.png";
import skinSpots from "../images/card-skin.png";



import suitcase from "../images/suitcase.svg";
import psychee from "../images/psychee.svg";
import handsholding from "../images/handsholding.svg";

const AboutSMPage = () => (
  <Layout route="about-sm">

    <Seo page="about-sm" title="About SM" description="How can systemic mastocytosis impact the lives of your patients? Explore SM symptoms and patient-reported outcomes of the disease." />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-10 large-10">
        <h1 className="color--purple text--watch-quinn">SM can cause debilitating symptoms and significantly impact patients&rsquo; lives<sup>1</sup></h1>
      </div>
    </div>

    <div id="overview" className="row align-middle lg_mt-4 mt-3">
      <div className="columns small-10 large-7">
        <div className="row small-collapse medium-uncollapse">
          <div className="columns">
            <div className="purple-card purple-card--xl text-left purple-card--left purple-card--bottom-right-img purple-card--bottom-right-img--germs" style={{backgroundImage : `url(${germBg})`}}>
            <p className="h3 mb-0 lg_mt-2 mt-2">Systemic mastocytosis (SM) <br className="show-for-medium"/>is a clonal mast cell neoplasm associated with the uncontrolled proliferation and activation of abnormal <br className="hide-for-medium"/>mast cells.<sup>2,3</sup></p>
          </div>
        </div>
        </div>
      </div>
      <div className="columns small-12 mt-4 medium-expand text-center">
        <div className="row align-center">
          <div className="columns small-8 large-8">
            <div className="hr"></div>
            <p className="h4 mb-0 color--powder lg_mt-1 mt-1"><strong>Estimated prevalence</strong></p>
            <p className="h1--xl mb-0 color--purple text--watch-quinn">~32,000</p>
            <p className="mb-0 h4 color--purple">US adults<sup>4</sup></p>
            <div className="hr"></div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-4 small-collapse medium-uncollapse">
      <div className="columns">
        <div className="hero-breaker hero-breaker--lesions" style={{"--bg-image": `url(${aboutSmDykBg})`}}>
          <div className="row align-middle align-justify">
            <div className="columns small-10 small-offset-1 large-offset-1 medium-expand">
              <p className="color--purple text--watch-quinn h2">Did you know?</p>
              <p className="color--purple h2 lead">A patient may be on several symptom-directed <br className="show-for-large"/>therapies to manage their disease, yet still <br className="show-for-large"/>experience major impacts to their quality of life<sup>1</sup></p>
              <AnchorLink to="/about-sm/#burden-of-sm" title="EXPLORE HOW" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">EXPLORE HOW</AnchorLink>
            </div>
            <div className="columns hero-breaker--person hero-breaker--person--lesions small-12 large-5" style={{"--bg-image": `url(${aboutSmDykLesions})`}}>
              <span className="visually-hidden">Woman-lifting-shirt-to-show-skin-lesions</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="row lg_mt-7 mt-4 align-center medum-align-left">
      <div className="columns small-11 medium-expand">
        <p className="h3 color--purple">The 4th edition World Health Organization (WHO) diagnostic criteria identify 2 types of SM, which are classified into 5 subtypes<sup>2</sup></p>
        <div className="row align-center lg_mt-1">
          <div className="columns large-11">
            <img src={aboutDesktop} width="100%" height="auto" className="lg_mt-3 mt-3 show-for-medium"/>
            <img src={aboutMobile} width="100%" height="auto" className="lg_mt-3 mt-3 hide-for-medium"/>
          </div>
        </div>
      </div>
    </div>

    <div className="row align-center lg_mt-3 mt-4 lg_mb-3 mb-3">
      <div className="columns large-4">
        <div className="hr--decore">
          <div className="decore"></div>
        </div>
      </div>
    </div>

    <div className="row align-center medium-align-left">
      <div className="columns small-11 large-12">
        <p className="h3 color--purple">SM can have detrimental long-term effects on patients regardless of subtype<sup>5-7</sup></p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-4 align-right small-collapse">
      <div className="columns small-11 large-12">
        <div className="callout-rounded callout-rounded--right">
          <div className="row align-justify">
            <div className="columns small-12 large-expand text-center">
              <p className="h3 text--watch-quinn color--purple mb-0">Advanced SM</p>
              <p className="lead color--purple mb-0">is associated with a decreased <br className="show-for-large"/>overall survival of</p>
              <p className="h4 color--powder mb-1 mb-0"><span className="h1 text--watch-quinn color--purple">&lt;3.5</span> <strong>years</strong></p>
              <p className="lead color--purple mb-0">due to organ damage<sup>5,6</sup>*</p>
            </div>
            <div className="columns mt-2 mb-2 relative large-1">
              <div className="hr hide-for-medium"></div>
              <div className="vr show-for-medium"></div>
            </div>
            <div className="columns small-12 large-expand text-center">
              <p className="h3 text--watch-quinn color--purple mb-0">Non-Advanced SM</p>
              <p className="lead color--purple mb-0">can progress over time to more advanced <br className="show-for-large"/>disease in subsets of patients<sup>7</sup></p>
              <div className="row mt-1">
                <div className="columns">
                  <p className="h4 color--powder mb-0"><span className="h1 text--watch-quinn color--purple">~5%</span> <strong>with ISM</strong></p>
                  <p className="h4 color--powder mb-0"><span className="h1 text--watch-quinn color--purple">~9%</span> <strong>with SSM</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns small-11 right-ref medium-12 lg_mt-2 mt-2">
        <ul className="hanging-ref">
          <li><span className="ref">*</span>Overall survival was examined in a retrospective study that included 342 consecutive adult patients with SM (includes 183 adults with Advanced SM) seen at the Mayo Clinic between 1976 and 2007. In ASM (n=41), overall survival was 41 months; 24 months in SM-AHN (n=138); 2 months in MCL (n=4). Median follow-up was 20.7 months.<sup>5</sup></li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-7 mt-4 small-collapse medium-uncollapse">
      <div className="columns small-11 large-12 rose-card rose-card--left text-left">
        <p className="h3 mb-0 lg_mt-2"><strong>SM is driven by the KIT D816V mutation in ~95% of cases<sup>8-10</sup></strong></p>
      </div>
    </div>

    <div className="row small-col-reverse align-center medium-align-left">
      <div className="columns small-11 medium-expand lg_mt-2 mt-1">
        <p className="h4 color--purple indent--kitd8">The KIT D816V mutation can result in uncontrolled proliferation of abnormal mast cells throughout the body, which can lead to symptoms of SM<sup>2</sup></p>
      </div>
    </div>

    <div id="symptoms" className="row lg_mt-7 mt-3 align-center medium-align-left">
      <div className="columns small-11 large-12">
        <p className="h2">
          <span className="color--powder text--watch-quinn h1--xl">20+</span> <span className="color--purple">unpredictable and potentially severe symptoms <br className="show-for-medium"/>can occur in patients with SM<sup>2,11,12</sup></span>
        </p>
        <p className="h3 color--purple lg_mt-2 mt-2">Hallmark symptoms often appear with<br className="show-for-medium"/> unexplained persistence or recurrence<sup>11-13</sup></p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 lg_mb-2 mb-2 align-center medium-align-left">
      <div className="columns small-11 text-center">
        <p className="color--purple lead"><strong>Select each of the cards below <br className="hide-for-medium"/>to learn more</strong></p>
      </div>
    </div>

    <div className="row lg_mt-7 mt-4">
      <div className="columns">
        <div className="about-cards">
          <div className="row about-cards__card-deck align-justify">
            <div className="columns small-12 large-expand">
              <FlipCard>
                <div className="front">
                  <img alt="Woman-holding-stomach-in-fetal-position" src={diarrheaCard} width="100%" height="auto"/>
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img alt="Gastrointestinal-system-icon" src={gi} width="100%" height="auto"/>
                      <p className="h1--xxl text--watch-quinn lg_mt-2 mt-1 mb-0 fw">64%</p>
                      <p className="lead"><strong>of patients <br/>experience diarrhea<sup>14</sup>*</strong></p>
                      <p className="lead text--regular mb-0">Whereas increased presence of gastrointestinal (GI) mucosal mast cells is seen in many common GI disorders, spindle-shaped mast cell aggregates and/or CD25 expression are unique to SM.<sup>15</sup></p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
            <div className="columns small-12 large-expand mt-2">
              <FlipCard>
                <div className="front">
                  <img alt="Woman-lifting-shirt-to-show-skin-lesions-card" src={skinLesionsCard} width="100%" height="auto"/>
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img alt="Magnified-skin-lesions-icon" src={skinSpots} width="100%" height="auto"/>
                      <p className="h1--xxl text--watch-quinn lg_mt-2 mt-1 mb-0 fw">95%</p>
                      <p className="lead"><strong>of patients have maculopapular skin lesions<sup>11&dagger;</sup></strong></p>
                      <p className="lead text--regular">Small monomorphic lesions often appear on the thighs or trunk of the body.<sup>11</sup></p>
                      <p className="lead text--regular mb-0">Wheal-and-flare reaction is elicited by stroking lesion with tongue spatula.<sup>11</sup></p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
            <div className="columns small-12 large-expand mt-2">
              <FlipCard>
                <div className="front">
                  <img alt="Woman-with-hand-on-chest" src={anaphylaxisCard} width="100%" height="auto"/>
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img alt="blood-vessel-icon" src={agChart} width="100%" height="auto"/>
                      <p className="h1--xxl text--watch-quinn lg_mt-2 mt-1 mb-0 fw">50%</p>
                      <p className="lead"><strong>of patients experience anaphylaxis<sup>11&dagger;</sup></strong></p>
                      <p className="lead text--regular">Commonly triggered by hymenoptera stings (such as bees or wasps) or can be idiopathic.<sup>16</sup></p>
                      <p className="lead text--regular mb-0">Anaphylaxis is often coupled with hypotension and can be life threatening.<sup>16</sup></p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
          </div>
          <div className="row about-cards__cta align-center lg_mt-2 mt-2">
            <div className="columns large-12 text-center">
              <p className="lead">Seeing any of these hallmark symptoms may warrant suspicion of SM.<sup>13</sup> <br className="show-for-large"/>High-sensitivity (&lt;1%) KIT D816V testing can help confirm that suspicion.<sup>17</sup></p>
              <AnchorLink to="/is-it-sm/#screen-for-sm" title="SCREEN FOR SM" className="btn btn--rose btn--small-fw lg_mt-1">SCREEN FOR SM</AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center">
      <div className="columns small-12">
        <ul className="hanging-ref lg_mt-2 mt-1">
          <li className="text--regular"><span className="ref">*</span>Data from a validation study of 2 patient-reported outcome measures of quality of life and symptom burden in 164 patients with indolent systemic mastocytosis.<sup>14</sup></li>
          <li className="text--regular"><span className="ref"><sup>†</sup></span>As described by an expert-panel review of adult-onset mastocytosis (predominantly indolent population).<sup>11</sup></li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-5 mt-4 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <p className="h3 color--purple">Explore a range of signs and symptoms across <br className="show-for-large"/> multiple organ systems for patients with SM<sup>12,18</sup></p>
        <p className="color--purple h5 lg_mt-2 mt-2"><strong>Select each of the cards below to learn more</strong></p>
      </div>
    </div>

    <div className="row lg_mt-3 mt-3 align-center">
      <div className="columns small-10 large-offset-0 large-4" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-slumped-in-chair-with-hand-on-head" src={systemicCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="Body-with-outline-of-organ-systems-icon" src={systemic} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>SYSTEMIC<sup>12</sup></strong></p>
                <ul>
                  <li>Fatigue</li>
                  <li>Weight loss</li>
                  <li>Anaphylaxis with hypotension</li>
                  <li>Malaise</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-10 large-offset-0 large-4 mt-3" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-coughing" src={respiratoryCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="lungs-icon" src={respiratory} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>RESPIRATORY<sup>18</sup></strong></p>
                <ul>
                  <li>Dyspnea</li>
                  <li>Nasal congestion</li>
                  <li>Throat swelling</li>
                  <li>Wheezing</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-10 large-offset-0 large-4 mt-3" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-cradling-knee" src={musculoCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="Body-with-outline-of-musculoskeletal-system-icon" src={musculoskeletal} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>MUSCULOSKELETAL<sup>12</sup></strong></p>
                <ul>
                  <li>Bone pain</li>
                  <li>Myalgia</li>
                  <li>Osteolytic lesions</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
    </div>

    <div className="row align-center lg_mt-2 mt-3">
      <div className="columns small-10 large-offset-0 large-4" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-lifting-shirt-to-show-skin-lesions-card" src={skinCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="Magnified-skin-lesions-icon" src={skinSpots} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>SKIN<sup>18</sup></strong></p>
                <ul>
                  <li>Pruritus</li>
                  <li>Flushing</li>
                  <li>Maculopapular lesions</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-10 large-offset-0 large-4 mt-3" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-cradling-knee" src={cardioCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="human-heart-icon" src={cardiovascular} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>CARDIOVASCULAR<sup>12</sup></strong></p>
                <ul>
                  <li>Hypotension</li>
                  <li>Dizziness</li>
                  <li>Palpitations</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
    </div>

    <div className="row align-center lg_mt-2 mt-3">
      <div className="columns small-10 large-offset-0 large-4" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-with-head-in-hands-card" src={neuroCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="Human-brain-icon" src={neuropsychiatric} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 mb-0 fw"><strong>NEUROPSYCHIATRIC<sup>18</sup></strong></p>
                <ul>
                  <li>Memory impairment</li>
                  <li>Brain fog</li>
                  <li>Anxiety</li>
                  <li>Migraines</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-10 large-offset-0 large-4 mt-3" style={{maxWidth : `360px`}}>
        <FlipCard>
          <div className="front">
            <img alt="Woman-holding-stomach-in-fetal-position" src={gastroCard} width="100%" height="auto"/>
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img alt="Gastrointestinal-system-icon" src={gi} width="100%" height="auto"/>
                <p className="h4 lg_mt-2 mt-2 fw mb-0"><strong>GASTROINTESTINAL<sup>12</sup></strong></p>
                <ul>
                  <li>Nausea/vomiting</li>
                  <li>Diarrhea</li>
                  <li>Abdominal pain</li>
                  <li>Heartburn or reflux</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-10 large-7 lg_mt-1 mt-2">
        <p className="mb-0 color--purple" style={{lineHeight : `1`}}>Not an exhaustive list of all symptoms.</p>
        <p className="mb-0 color--purple">Symptoms may vary from person to person.</p>
      </div>
    </div>

    <div className="row lg_mt-4 mt-4 align-center medium-align-left">
      <div className="columns small-11 medium-expand text-center">
        <p className="h4 color--purple">Organopathy due to mast cell infiltration can also occur, causing <strong>lymphadenopathy, splenomegaly/hypersplenism, hepatomegaly/ascites, cytopenias,</strong> or <strong>malabsorption with weight loss due to gastrointestinal mast cell infiltrates</strong>.<sup>6,12</sup></p>
      </div>
    </div>

    <div className="row lg_mt-4 mt-2">
      <div className="columns text-center">
        <div className="hr--decore">
          <div className="decore"></div>
        </div>
        <p className="color--purple text--watch-quinn h1 lg_mt-1">~9 of 10 patients</p>
        <p className="h3 text--regular color--purple lg_mb-2 mb-1">report experiencing ≥10 symptoms over the course of <br className="hide-for-medium"/>their disease<sup>1&Dagger;</sup></p>
        <div className="hr"></div>
      </div>
    </div>

    <div className="row align-middle small-collapse medium-uncollapse lg_mt-6 mt-4">
      <div className="columns small-11 large-8">
        <div className="yellow-card yellow-card--xl text-left yellow-card--left yellow-card--bottom-right-img" style={{backgroundImage : `url(${bgsun})`}}>
          <p className="h3 mb-0 lg_mt-2 mt-2">Symptoms can be triggered by aspects of everyday life, including heat, stress, exercise, and food<sup>19</sup></p>
        </div>
      </div>
      <div className="columns small-12 medium-expand text-center mt-2">
        <div className="row align-center">
          <div className="columns large-8">
            <p className="h4 mb-0 color--powder lg_mt-1 mt-1"><strong>Heat triggered</strong></p>
            <p className="h1--xl mb-0 color--purple text--watch-quinn">82%</p>
            <p className="mb-0 lead color--purple">of patients<sup>19&#xA7;</sup></p>
          </div>
        </div>
        <div className="row">
          <div className="columns small-10 small-offset-1 large-6 large-offset-3 lg_mt-1 lg_mb-1">
            <div className="hr"></div>
          </div>
        </div>
        <div className="row align-center">
          <div className="columns large-8">
            <p className="h4 mb-0 color--powder lg_mt-1 mt-1"><strong>Stress triggered</strong></p>
            <p className="h1--xl mb-0 color--purple text--watch-quinn">81%</p>
            <p className="mb-0 lead color--purple">of patients<sup>19&#xA7;</sup></p>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-4 mt-4 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <ul className="hanging-ref">
          <li><span className="ref">&Dagger;</span>In a Blueprint Medicines study, US adults with a self-reported SM diagnosis (N=56) completed an online survey of 100 items, including the 12-item Short-Form Health Survey, the ISM Symptom Assessment Form, and the Work Productivity and Activity Impairment Questionnaire, as well as questions about disease impact. The results were analyzed using descriptive statistics.<sup>1</sup></li>
          <li><span className="ref">&#xA7;</span>Based on data from 366 patients with self-reported mastocytosis or mast cell activations.<sup>19</sup></li>
        </ul>
      </div>
    </div>


    <div className="row lg_mt-7 small-collapse medium-uncollapse">
      <div className="columns">
        <div className="hero-breaker hero-breaker--progress" style={{"--bg-image": `url(${aboutSmProgrsssBg})`}}>
          <div className="row align-middle small-col-reverse">
            <div className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--progress small-12 large-5" style={{"--bg-image": `url(${aboutSmProgress})`}}>
              <span className="visually-hidden">Woman-with-head-in-hands</span>
            </div>
            <div className="columns small-12 large-7 text-right mt-6">
              <div className="row">
                <div className="columns large-10">
                  <p className="color--purple text--watch-quinn h2">Did you know?</p>
                  <p className="color--purple h2 lead">Symptoms of SM can <br className="hide-for-medium"/>mimic other disorders.<sup>20</sup></p>
                  <p className="color--purple h2 lead"><strong>Identifying the key <br className="hide-for-medium"/>differences can help in <br className="hide-for-medium"/><br className="show-for-large"/>making an accurate diagnosis.</strong></p>
                  <Link to="/is-it-sm/" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">EXPLORE MORE</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="burden-of-sm" className="row align-right lg_mt-7 mt-4 small-collapse medium-uncollapse">
      <div className="columns small-11 large-11">
        <div className="callout-rounded callout-rounded--grey callout-rounded--right">
          <div className="row">
            <div className="columns large-10">
              <p className="h3 color--purple"><strong>Debilitating symptoms of SM can significantly disrupt patients’ lives<sup>1</sup>*</strong></p>
              <p className="h5 lg_mt-2 mt-2 color--purple">In the TouchStone SM Patient Survey&#8212;developed by Blueprint Medicines in collaboration with SM physician experts and SM patient advocates&#8212;patients (N=56) reported professional, psychosocial, and psychological consequences</p>
            </div>
          </div>

          <div className="row lg_mt-4 mt-4 align-justify">
            <div className="columns small-12 medium-shrink" style={{minWidth: `200px`}}>
              <img alt="briefcase-icon" className="icons__sympt" src={suitcase}/>
            </div>
            <div className="columns">
              <p className="color--purple strong lead mt-2">PROFESSIONAL</p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">54%</span> <span className="color--purple">reported <strong>reducing work hours</strong> due to SM<sup>1</sup></span></p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">88%</span> <span className="color--purple">reported <strong>SM impacts productivity</strong> at work<sup>4&dagger;&Dagger;</sup></span></p>
            </div>
          </div>

          <div className="row lg_mt-8 mt-4 align-justify">
            <div className="columns small-12 medium-shrink" style={{minWidth: `200px`}}>
              <img className="icons__sympt" src={handsholding} alt="holding-hands-icon"/>
            </div>
            <div className="columns">
              <p className="color--purple strong lead mt-2">PSYCHOSOCIAL</p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">91%</span> <span className="color--purple">said their <strong>physical or emotional health interfered with social activities</strong><sup>4&dagger;&sect;</sup></span></p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">64%</span> <span className="color--purple">reported <strong>avoiding leaving home</strong> due to SM symptoms<sup>1</sup></span></p>
            </div>
          </div>

          <div className="row lg_mt-8 mt-4 align-justify">
            <div className="columns small-12 medium-shrink" style={{minWidth: `200px`}}>
              <img className="icons__sympt" src={psychee} alt="head-profile-icon"/>
            </div>
            <div className="columns">
              <p className="color--purple strong lead mt-2">PSYCHOLOGICAL</p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">79%</span> <span className="color--purple">reported <strong>feeling depressed and discouraged</strong> because of their disease<sup>4&dagger;&sect;</sup></span></p>
              <p className="h5"><span className="h1 text--watch-quinn color--powder">93%</span> <span className="color--purple">reported <strong>feeling anxious or worried</strong> about their disease<sup>4&dagger;&sect;</sup></span></p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className="row small-collapse medium-uncollapse">
      <div className="columns small-11 large-11">
        <div className="callout-rounded callout-rounded--blue callout-rounded--left pt-4 pb-4">
          <div className="row">
            <div className="columns">
              <p className="h3 strong color--purple">Patients with SM report a substantial delay in diagnosis<sup>1</sup></p>
            </div>
          </div>
          <div className="row lg_mt-2 mt-2 align-middle">
            <div className="columns text-left shrink">
              <p className="h3 color--powder mb-0"><span className="text--watch-quinn color--purple h1--xxl mb-0">~6</span> <strong>years</strong></p>
              <p className="h5 color--purple mb-0">Average time from symptom <br className="show-for-large"/><br className="hide-for-medium"/>onset to diagnosis<sup>1</sup>*</p>
            </div>
            <div className="columns small-12 relative stretch large-1 mt-1">
              <div className="hr hide-for-medium"></div>
              <div className="vr show-for-medium"></div>
            </div>
            <div className="columns small-12 medium-expand mt-1">
              <p className="h4 color--purple"><strong>In that time, patients may present to a diverse group of specialists, including<sup>1,21</sup>:</strong></p>
              <ul className="ul-standard ul-standard--half text--regular">
                <li className="color--purple">Allergist/immunologists</li>
                <li className="color--purple">Dermatologists</li>
                <li className="color--purple">Gastroenterologists</li>
                <li className="color--purple">Hematologist/oncologists</li>
                <li className="color--purple">Endocrinologists</li>
                <li className="color--purple">Primary care practitioners</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-4">
      <div className="columns text-center">
        <div className="purple-card purple-card--no-bottom">
          <p className="h3 mb-0 lg_mt-2 mt-1 strong">Polypharmacy is common in patients with SM<sup>1</sup>*</p>
          <div className="row lg_mt-3">
            <div className="columns small-12 medium-expand lg_pb-3 lg_mt-2 mt-2">
              <p className="text--watch-quinn h1--xxl mb-0">61%</p>
              <p className="h5 mb-0">reported taking ≥3 OTC medications</p>
            </div>
            <div className="columns relative stretch large-1 mt-2">
              <div className="hr hr--white hide-for-medium"></div>
              <div className="vr vr--white show-for-medium"></div>
            </div>
            <div className="columns small-12 medium-expand lg_pb-3 lg_mt-2 mt-2">
              <p className="text--watch-quinn h1--xxl mb-0">52%</p>
              <p className="h5 mb-0">reported taking ≥3 prescription medications</p>
            </div>
            <div className="columns relative stretch large-1 mt-2">
              <div className="hr hr--white hide-for-medium"></div>
              <div className="vr vr--white show-for-medium"></div>
            </div>
            <div className="columns small-12 medium-expand lg_pb-3 pb-1 lg_mt-2 mt-2">
              <p className="text--watch-quinn h1--xxl mb-0">88%</p>
              <p className="h5 mb-0">reported being prescribed epinephrine for emergency use in the event of an anaphylactic episode</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-5 mt-4">
      <div className="columns text-center">
        <div className="hr--decore">
          <div className="decore"></div>
        </div>
        <p className="color--purple text--watch-quinn h2 lg_mb-1">Despite polypharmacy with symptom-directed therapies, many patients still have significant symptom burden<sup>1</sup></p>
        <div className="hr"></div>
      </div>
    </div>

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-11 medium-expand">
        <ul className="hanging-ref">
          <li><span className="ref">*</span>In a Blueprint Medicines study, US adults with a self-reported SM diagnosis (N=56) completed an online survey of 100 items, including the 12-item Short-Form Health Survey, the ISM Symptom Assessment Form, and the Work Productivity and Activity Impairment Questionnaire, as well as questions about disease impact. The results were analyzed using descriptive statistics.<sup>1</sup></li>
          <li><span className="ref">&dagger;</span>These analyses were made from the Touchstone Patient SM Survey but have not been published.</li>
          <li><span className="ref">&Dagger;</span>Out of patients employed full time/part time (n=26), 23 reported some impact to productivity. Patients were asked to select, on a scale from 0-10, whether SM affected productivity at work during a 7-day period where 10 indicated impact on work and 0 indicated no impact on work.<sup>4</sup></li>
          <li><span className="ref">&sect;</span>Patients responded “Yes” to the statement.</li>
          <li><span className="ref">&nbsp;</span>OTC=over-the-counter.</li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-11 medium-expand text-right">
        <p className="h4 color--purple">Learn about screening for <br className="hide-for-medium"/>KIT D816V  <br className="show-for-large"/>in patients <br className="hide-for-medium"/>with suspected SM</p>
        <Link className="btn btn--outline btn--icon uppercase mt-1" to="/is-it-sm/">Suspect SM <FontAwesomeIcon className="color--powder" icon={faAngleRight}/></Link>
      </div>
    </div>

    <FooterCrown type='about'/>

    <ReferenceBlock>
      <ol className="color--purple">
        <li>Mesa RA et al. <em>Cancer</em> (Open Access). Published online August 23, 2022. doi:10.1002/cncr.34420 </li>
        <li>Gilreath JA et al. <em>Clin Pharmacol.</em> 2019;11:77-92.</li>
        <li>Vaes M et al. <em>Front Med.</em> 2017;4:110.</li>
        <li>Data on file. Blueprint Medicines Corporation, Cambridge, MA. 2022.</li>
        <li>Lim K-H et al. <em>Blood.</em> 2009;113(23):5727-5736.</li>
        <li>G&#252;len T et al. <em>J Intern Med.</em> 2016;279(3):211-228. </li>
        <li>Trizuljak J et al. <em>Allergy.</em> 2020;75(8):1927-1938.</li>
        <li>Garcia-Montero AC et al. <em>Blood.</em> 2006;108(7):2366-2372.</li>
        <li>Evans EK et al. <em>Sci Transl Med.</em> 2017;9(414):eaao1690.</li>
        <li>Kristensen T et al. <em>J Mol Diagn.</em> 2011;13(2):180-188.</li>
        <li>Hartmann K et al. <em>J Allergy Clin Immunol.</em> 2016;137(1):35-45.</li>
        <li>Pardanani A. <em>Am J Hematol.</em> 2021;96(4):508-525.</li>
        <li>Shomali W, Gotlib J. <em>Hematology.</em> 2018;2018(1):127-136.</li>
        <li>van Anrooij B et al. <em>Allergy.</em> 2016;71(11):1585-1593.</li>
        <li>Hahn HP, Hornick JL. <em>Am J Surg Pathol.</em> 2007;31(11):1669-1676.</li>
        <li>G&#252;len T et al. J <em>Allergy Clin Immunol Pract.</em> 2017;5:1248-1255.</li>
        <li>Hoermann G et al. <em>J Allergy Clin Immunol Pract.</em> 2022;10(8):1953-1963. </li>
        <li>Theoharides TC et al. <em>N Engl J Med.</em> 2015;373(2):163-172.</li>
        <li>Jennings S et al. <em>J Allergy Clin Immunol Pract.</em> 2014;2(1):70-76.</li>
        <li>NORD. Rare disease database: mastocytosis. Accessed August 17, 2022. https://rarediseases.org/rare-diseases/mastocytosis/</li>
        <li>Russell N et al. <em>J Allergy Clin Immunol Pract.</em> 2019;7(4):1157-1165.</li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default AboutSMPage
